import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import avatarFB from '@/assets/images/avatar-fb.png';
import avatarIG from '@/assets/images/avatar-ig.png';
import useInboxStore from '@/stores/InboxStore';
import { useHotkeys } from '@blueprintjs/core';
import { ReloadIcon } from '@radix-ui/react-icons';
import { intervalToDuration, parseISO, set } from 'date-fns';
import {
  FacebookIcon,
  InstagramIcon,
  Loader2,
  Loader2Icon,
} from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  trackContactFocus,
  trackConversationFetch,
  trackRefreshConversationButtonClicked,
} from '@/lib/analytics-event';
import { cn } from '@/lib/utils';
import { fetchConversations } from '@/hooks/inbox';

import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { Card, CardContent } from '../ui/card';
import { ScrollArea } from '../ui/scroll-area';

const ConversationsList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { ref: inViewRef, inView } = useInView();
  const [messageIsHovered, setMessageIsHovered] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);

  const { conversations, setConversations, setNextConversations } =
    useInboxStore(
      useShallow((state) => ({
        conversations: state.conversations,
        setConversations: state.setConversations,
        setNextConversations: state.setNextConversations,
      }))
    );

  const page = useInboxStore((state) => state.page);

  const {
    selectedTags,
    selectedOwners,
    selectedChannels,
    searchText,
    selectedConversationStatus,
    selectedSortOrder,
    focusedIndex,
    setFocusedIndex,
    conversationListUpdateStatusCode,
    setConversationListUpdateStatusCode,
    socketUpdateCount,
    resetSocketCount,
  } = useInboxStore(
    useShallow((state) => ({
      selectedTags: state.selectedTags,
      selectedOwners: state.selectedOwners,
      selectedChannels: state.selectedChannels,
      searchText: state.searchText,
      selectedConversationStatus: state.selectedConversationStatus,
      selectedSortOrder: state.selectedSortOrder,
      focusedIndex: state.focusedIndex,
      setFocusedIndex: state.setFocusedIndex,
      conversationListUpdateStatusCode: state.conversationListUpdateStatusCode,
      setConversationListUpdateStatusCode:
        state.setConversationListUpdateStatusCode,
      socketUpdateCount: state.socketUpdateCount,
      resetSocketCount: state.resetSocketCount,
    }))
  );

  const { pathname } = location;

  const defaultPic = `https://files.sbccrm.com/sbccrm/sbccrm/contacts/pics/no-pic.jpg`;

  const fetchData = useCallback(
    async (type, page) => {
      try {
        const response = await fetchConversations(
          {
            tags: selectedTags,
            agents: selectedOwners,
            channel: selectedChannels,
            searchText: searchText,
          },
          page,
          selectedConversationStatus,
          selectedSortOrder
        );
        if (type === 'new') {
          const { data, next_page_url, current_page, total } = response;
          setConversations(
            data,
            next_page_url ? current_page + 1 : null,
            total
          );

          if (isContactOpen) {
            navigate('/inbox');
          }
          setConversationListUpdateStatusCode(0);
        } else {
          const { data, next_page_url, current_page, total } = response;
          setNextConversations(
            data,
            next_page_url ? current_page + 1 : null,
            total
          );

          setConversationListUpdateStatusCode(1);
        }
      } catch (error) {
        console.error('error fetching conversations:', error);
      }
    },
    [
      navigate,
      setConversations,
      setNextConversations,
      selectedTags,
      selectedOwners,
      selectedChannels,
      searchText,
      selectedConversationStatus,
      selectedSortOrder,
      setConversationListUpdateStatusCode,
    ]
  );

  const handleSocketCountRefresh = () => {
    fetchData('new', 1);
    trackRefreshConversationButtonClicked();
    resetSocketCount();
  };

  useEffect(() => {
    fetchData('new', 1);
    trackConversationFetch(
      searchText,
      selectedConversationStatus.value,
      selectedSortOrder,
      selectedTags,
      selectedOwners
    );
    resetSocketCount();
  }, [
    setConversations,
    navigate,
    fetchData,
    selectedTags,
    selectedOwners,
    searchText,
    selectedConversationStatus,
    selectedSortOrder,
    resetSocketCount,
  ]);

  useEffect(() => {
    if (inView) {
      console.log('in view');
      fetchData('next', page);
    }
  }, [inView, fetchData]);

  // TODO: Fix the time diff error identified in prod
  const getTimeDiff = (timestamp) => {
    const date = parseISO(timestamp);
    const duration = intervalToDuration({ start: date, end: new Date() });

    const {
      years = 0,
      months = 0,
      days = 0,
      hours = 0,
      minutes = 0,
      seconds = 0,
    } = duration;

    const totalHours = years * 8760 + months * 730 + days * 24 + hours;
    const totalMinutes = totalHours * 60 + minutes;
    const totalSeconds = totalMinutes * 60 + seconds;

    let timeString = '';
    if (totalHours >= 48) {
      if (duration.years > 0) {
        timeString = `${duration.years}y`;
      } else if (duration.months > 0) {
        timeString = `${duration.months}mth`;
      } else {
        timeString = `${duration.days}d`;
      }
    } else if (totalHours >= 1) {
      timeString = `${totalHours}h`;
    } else if (totalMinutes >= 1) {
      timeString = `${totalMinutes}m`;
    } else {
      timeString = `${totalSeconds}s`;
    }

    return timeString;
  };

  const cardRefs = useRef([]);

  const handleKeyDownScroll = (event) => {
    if (
      event.altKey &&
      (event.key === 'ArrowUp' || event.key === 'ArrowDown')
    ) {
      event.preventDefault();
    }
  };

  const handleBasicKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      setIsContactOpen(true);
      navigate(`/inbox/${conversations[index]._id}`, { replace: true });
    }

    if (event.key === 'Escape') {
      event.target.blur();
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (conversationListUpdateStatusCode === 0) {
      const index = conversations.findIndex((conversation) => {
        return conversation._id == id;
      });
      setFocusedIndex(index == -1 ? 0 : index);
    }
  }, [id, conversations, setFocusedIndex, conversationListUpdateStatusCode]);

  useEffect(() => {
    if (conversationListUpdateStatusCode == 1) {
      setConversationListUpdateStatusCode(2);
    }
  }, [conversationListUpdateStatusCode, setConversationListUpdateStatusCode]);

  const handleUpKeyPress = useCallback(() => {
    if (focusedIndex > 0) {
      cardRefs.current[focusedIndex - 1].focus();

      setFocusedIndex(focusedIndex - 1);
      trackContactFocus('keyboard');
    }
  }, [focusedIndex, setFocusedIndex]);

  const handleDownKeyPress = useCallback(() => {
    if (focusedIndex < cardRefs.current.length - 1) {
      cardRefs.current[focusedIndex + 1].focus();
      setFocusedIndex(focusedIndex + 1);
      trackContactFocus('keyboard');
    }
  }, [focusedIndex, setFocusedIndex]);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'alt + arrowup',
        global: true,
        label: 'Move Conversation - Up',
        onKeyUp: handleUpKeyPress,
      },
      {
        combo: 'alt + arrowdown',
        global: true,
        label: 'Move Conversation - Down',
        onKeyUp: handleDownKeyPress,
      },
    ],
    [handleUpKeyPress, handleDownKeyPress]
  );
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  return (
    <>
      <nav className='m-0 flex  space-x-2   overflow-hidden   lg:flex-col lg:space-x-0 lg:space-y-1'>
        <ScrollArea onKeyDown={handleKeyDownScroll}>
          <ul role='list' className='m-[1px] space-y-[2px]'>
            {socketUpdateCount > 0 && (
              <li
                className='flex cursor-pointer flex-col items-center justify-center border bg-primary py-2 text-xs text-card-foreground ring-offset-background focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0'
                onMouseEnter={() => setMessageIsHovered(true)}
                onMouseLeave={() => setMessageIsHovered(false)}
                onClick={handleSocketCountRefresh}
              >
                {messageIsHovered ? (
                  <div className='flex items-center justify-center space-x-3'>
                    <ReloadIcon />
                    <span>Click to refresh</span>
                  </div>
                ) : (
                  <span>
                    {socketUpdateCount} new event
                    {socketUpdateCount > 1 ? 's' : ''}
                  </span>
                )}
              </li>
            )}
            {conversations.map((contact, index) => (
              <li
                key={index}
                ref={(el) => {
                  const isLastItem = index === conversations.length - 1;
                  if (isLastItem && page !== null && page !== 0) {
                    inViewRef(el);
                  }

                  cardRefs.current[index] = el;
                }}
                tabIndex={0}
                onKeyDown={(event) => handleBasicKeyPress(event, index)}
                onClick={() => setIsContactOpen(true)}
                className='ring-offset-background focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0'
              >
                <Link
                  to={`/inbox/${contact._id}`}
                  onClick={() => trackContactFocus('mouse')}
                  replace
                >
                  <Card
                    className={cn(
                      pathname === `/inbox/${contact._id}`
                        ? 'bg-secondary'
                        : '',
                      'rounded-none border-none hover:bg-secondary'
                    )}
                  >
                    <CardContent className='grid px-2 py-3'>
                      <div className='flex min-w-0 items-center justify-between space-x-4'>
                        <div className='flex min-w-0 items-start space-x-4'>
                          <div className='relative'>
                            {contact.channel === 'instagram' ? (
                              <img
                                src={avatarIG}
                                className='absolute -bottom-1 -right-1 z-50 aspect-square h-4 w-4 '
                              />
                            ) : // <InstagramIcon className='absolute bottom-0 left-0 z-50 h-4 w-4 bg-primary text-primary-foreground' />
                            contact.channel === 'facebook' ? (
                              // <FacebookIcon className='absolute bottom-0 left-0 z-50 h-4 w-4 bg-primary text-primary-foreground' />
                              <img
                                src={avatarFB}
                                className='absolute -bottom-1 -right-1 z-50 aspect-square h-4 w-4 '
                              />
                            ) : null}
                            <Avatar>
                              {contact.profile_picture_url ? (
                                <AvatarImage
                                  src={contact.profile_picture_url}
                                />
                              ) : (
                                <AvatarImage src={defaultPic} />
                              )}
                              <AvatarFallback>
                                <AvatarImage src={defaultPic} />
                                {contact.full_name[0]}
                              </AvatarFallback>
                            </Avatar>
                          </div>
                          <div className='min-w-0 space-y-0'>
                            <p className=' truncate pb-1.5 text-sm font-medium leading-none'>
                              {contact.full_name}
                            </p>
                            <p className='truncate text-sm text-muted-foreground '>
                              {contact.latest_message_text}
                            </p>
                          </div>
                        </div>

                        <span className='text-xs opacity-50'>
                          {getTimeDiff(contact.latest_message_timestamp)}
                        </span>
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              </li>
            ))}
          </ul>
          {page !== null && page !== 0 && (
            <div className='flex items-center justify-center py-3'>
              <Loader2Icon className='h-4 w-4 animate-spin' />
            </div>
          )}
        </ScrollArea>
      </nav>
    </>
  );
};

export default ConversationsList;
