import logo from '@/assets/images/logo.png';
import { BarChart2, Contact, MessageSquareMore, Settings } from 'lucide-react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { ModeToggle } from '../common/ThemeToggle';
import { UserAvatarMenu } from './UserAvatarMenu';

const navigation = [
  {
    name: 'Inbox',
    href: 'inbox',
    icon: MessageSquareMore,
    current: true,
  },
  {
    name: 'Contacts',
    href: 'contacts',
    icon: Contact,
    current: false,
  },
  {
    name: 'Reports',
    href: 'reports',
    icon: BarChart2,
    count: '12',
    current: false,
  },
  {
    name: 'Settings',
    href: 'settings',
    icon: Settings,
    count: '20+',
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
  const { pathname } = useLocation();
  const currentPath = pathname.split('/');

  return (
    <>
      <div className='fixed inset-y-0 z-10 flex w-16 flex-col'>
        <div className='flex h-screen grow flex-col items-center justify-between gap-y-5 overflow-y-auto border-r bg-background px-2'>
          <div className='flex h-16 shrink-0 items-center'>
            <img className='h-10 w-auto' src={logo} alt='Your Company' />
          </div>
          <nav className='flex flex-1 flex-col'>
            <ul
              role='list'
              className='flex flex-1 flex-col items-center justify-center gap-y-7'
            >
              <li>
                <ul role='list' className='-mx-2 space-y-12'>
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className={classNames(
                          currentPath.includes(item.href)
                            ? 'text-primary'
                            : 'text-gray-700 hover:text-primary ',
                          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            currentPath.includes(item.href)
                              ? 'text-primary'
                              : 'text-gray-400 group-hover:text-primary',
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden='true'
                        />
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
          <div className='flex flex-col items-center gap-y-7 py-3 text-sm font-semibold leading-6 text-gray-900 hover:cursor-pointer'>
            <ul role='list' className='-mx-2 space-y-12'>
              <li>
                <ModeToggle />
              </li>
            </ul>
            <UserAvatarMenu />
          </div>
        </div>
      </div>
      <main className='ml-16'>
        <Outlet />
      </main>
    </>
  );
}
