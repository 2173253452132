import useContactStore from '@/stores/ContactStore';
import useInboxStore from '@/stores/InboxStore';
import useUserStore from '@/stores/UserStore';
import { useChannel } from 'ably/react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

export const AblyMessages = () => {
  const { customerId, clientId, userId } = useUserStore(
    useShallow((state) => ({
      customerId: state.customerId,
      clientId: state.clientId,
      userId: state.user?._id,
    }))
  );

  const {
    selectedTags,
    selectedOwners,
    selectedChannels,
    selectedConversationStatus,
    updateConversationMessageSocket,
    searchText,
    addConversationToTop,
    removeConversation,
    increaseConversationCount,
    decreaseConversationCount,
    incrementSocketUpdateCount,
  } = useInboxStore(
    useShallow((state) => ({
      selectedTags: state.selectedTags,
      selectedOwners: state.selectedOwners,
      selectedChannels: state.selectedChannels,
      selectedConversationStatus: state.selectedConversationStatus,
      searchText: state.searchText,
      updateConversationMessageSocket: state.updateConversationMessageSocket,
      addConversationToTop: state.addConversationToTop,
      removeConversation: state.removeConversation,
      increaseConversationCount: state.increaseConversationCount,
      decreaseConversationCount: state.decreaseConversationCount,
      incrementSocketUpdateCount: state.incrementSocketUpdateCount,
    }))
  );

  const {
    contactId,
    setContactStatus,
    setContactOwner,
    removeContactTag,
    addContactTag,
    setNewMessage,
    setMessageReaction,
    setMessageReadReceipt,
  } = useContactStore(
    useShallow((state) => ({
      contactId: state._id,
      setContactStatus: state.setContactStatus,
      setContactOwner: state.setContactOwner,
      removeContactTag: state.removeContactTag,
      addContactTag: state.addContactTag,
      setNewMessage: state.setNewMessage,
      setMessageReaction: state.setMessageReaction,
      setMessageReadReceipt: state.setMessageReadReceipt,
    }))
  );

  const channelName = `customer-${customerId}-messages`;
  const { id } = useParams();

  // Filter check function for new-message events
  const filtersCheck = (contactOwnerId, contactTags, contactChannel) => {
    // Check if the selectedOwners filter is empty or if the owner of the contact is part of the filter
    const isOwnerValid =
      selectedOwners.length === 0 || selectedOwners.includes(contactOwnerId);

    // Check if the selectedTags filter is empty or if the tags of the contact is part of the filter
    const isTagValid =
      selectedTags.length === 0 ||
      selectedTags.some((tagId) =>
        contactTags.some((tag) => tag._id === tagId)
      );

    const isChannelValid =
      selectedChannels.length === 0 || selectedChannels.inludes(contactChannel);
    console.log(
      'owner -> ',
      isOwnerValid,
      ', tag -> ',
      isTagValid,
      ', channel -> ',
      isChannelValid
    );

    return isOwnerValid && isTagValid;
  };

  // Filter check function for agent-update events
  const ownerFilterCheck = (prevAgent, newAgent) => {
    const condition1 = selectedOwners.includes(prevAgent);
    const condition2 = selectedOwners.includes(newAgent);

    // If no owner filter is selected, just ignore
    if (!selectedOwners.length) {
      return 'ignore';
    }

    // If prevOwner is passing filter and newOwner is passing filter, just ignore
    if (condition1 && condition2) {
      return 'ignore';
    }

    // If prevOwner is passing filter and newOwner is not passing filter, just remove conversation
    if (condition1 && !condition2) {
      return 'remove';
    }

    // If prevOwner is not passing filter and newOwner is passing filter, insert the conversation to the top
    if (!condition1 && condition2) {
      return 'insert';
    }

    // If prevOwner is not passing filter and newOwner is also not passing filter, just ignore
    return 'ignore';
  };

  // Filter check function for tag-update(remove) events
  const removedTagCheck = (removedTag, currentTags) => {
    const condition1 = selectedTags.includes(removedTag._id);
    const condition2 = currentTags.some((tagId) =>
      selectedTags.some((tag) => tag === tagId)
    );

    console.log('tag removal conditions');
    console.log('old state -> ', condition1);
    console.log('new state -> ', condition2);

    // if no tags filter is selected, just ignore
    if (!selectedTags.length) {
      return 'ignore';
    }

    // 1. if removed tag is part of the filter and currentTags are also part of the filter, then ignore
    // 2. if removed tag is part of the filter and currentTags are not part of the filter, then remove the conversation from the list
    if (condition1) {
      return condition2 ? 'ignore' : 'remove';
    }

    return 'ignore';
  };

  // Filter check function for tag-update(add) events
  const addedTagCheck = (addedTag, oldTags) => {
    const condition1 = oldTags.some((tagId) =>
      selectedTags.some((tag) => tag === tagId)
    );
    const condition2 = selectedTags.includes(addedTag._id);

    console.log('tag addition conditions');
    console.log('old state -> ', condition1);
    console.log('new state -> ', condition2);

    // if no tags filter is selected, just ignore
    if (!selectedTags.length) {
      return 'ignore';
    }

    // 1. if oldTags are not part of the filter and newly added tag is part of the filter, then insert the conversation to the top
    // 2. if oldTags are not part of the filter and newly added tag is also not part of the filter, then ignore
    if (!condition1) {
      return condition2 ? 'insert' : 'ignore';
    }

    return 'ignore';
  };

  // Filter to construct the message object
  const createMessageData = (data) => ({
    _id: data.message_id,
    text: data.text,
    timestamp: data.timestamp,
    direction: data.direction,
    user_id: data.user_id,
    is_seen: data.is_seen,
    emoji: data.emoji,
    message_type: data.message_type || null,
    media_url: data.media_url || null,
  });

  // Function to handle new-message events
  const handleNewMessage = (data) => {
    console.log('-----');
    console.log(
      'new-message',
      `user_id ${data.user_id} - current_user_id ${userId} `,
      data.contact.full_name,
      data.contact.latest_message_text
    );
    const messageData = createMessageData(data);

    // current contact is the socket contact, then update the message to the contact messages
    if (
      contactId === data.contact_id &&
      contactId === id &&
      ((data.direction === 'sent' && data.user_id !== userId) ||
        data.direction === 'received')
    ) {
      setNewMessage(messageData);
    }

    if (
      (selectedConversationStatus.value === 'all' ||
        selectedConversationStatus.value === data.contact.status) &&
      ((data.direction === 'sent' && data.user_id !== userId) ||
        data.direction === 'received') &&
      searchText === ''
    ) {
      // If (selectedConversationStatus is all / incoming socket contact status) and (direction is received or direction is sent by another user), then proceed
      console.log('status and direction passed');

      if (
        filtersCheck(
          data.contact.user_id,
          data.contact.tags,
          data.contact.channel
        ) === true
      ) {
        // incoming message passes the filter check, then insert/update the conversation to the conversations list
        console.log('filter passed');
        updateConversationMessageSocket(data.contact);
      }
    }
    console.log('-----');
  };

  // Function to handle agent-update events
  const handleAgentUpdate = (data) => {
    if (data.origin_client_id !== clientId) {
      const oldOwner = data.old_owner_id;
      const newOwner = data.new_owner_id;

      if (contactId === data.contact._id) {
        //TODO: current contact is the socket contact, then update the owner
        // setContactOwner(newOwner);

        toast.info('Current contact owner has been updated !');
      }

      if (
        (selectedConversationStatus.value === 'all' ||
          selectedConversationStatus.value === data.contact.status) &&
        (selectedTags.length === 0 ||
          selectedTags.some((tagId) =>
            data.contact.tag_ids.some((tag) => tag === tagId)
          ))
      ) {
        const updateType = ownerFilterCheck(oldOwner, newOwner);

        if (updateType === 'insert') {
          //TODO: Add the conversation to the top and increment the count
          // addConversationToTop(data.contact);
          // increaseConversationCount();

          incrementSocketUpdateCount();
        } else if (updateType === 'remove') {
          //TODO: Remove the conversation and decrement the count
          // removeConversation(data.contact._id);
          // decreaseConversationCount();

          incrementSocketUpdateCount();
        }
      }
    }
  };

  // Function to handle tag-update events
  const handleTagUpdate = (data) => {
    console.log('---tag-update--');
    if (data.origin_client_id !== clientId) {
      console.log('clientId -> ', data.origin_client_id, clientId);

      if (contactId === data.contact._id) {
        // current contact is the socket contact, then add/remove the tag
        if (data.type === 'remove') {
          const removedTag = data.removed_tag[0];
          removeContactTag(removedTag._id);
        } else if (data.type === 'add') {
          const addedTag = data.new_tag[0];
          addContactTag(addedTag);
        }
      }

      if (
        (selectedConversationStatus.value === 'all' ||
          selectedConversationStatus.value === data.contact.status) &&
        (selectedOwners.length === 0 ||
          selectedOwners.includes(data.contact.user_id))
      ) {
        if (data.type === 'remove') {
          // Scenario - Tag Removal
          const removedTag = data.removed_tag[0];
          const currentTagIds = data.contact.tag_ids;

          const updateType = removedTagCheck(removedTag, currentTagIds);

          console.log('tag removal -> ', updateType);

          if (updateType === 'remove') {
            //TODO: Remove the conversation and decrement the count
            // removeConversation(data.contact._id);
            // decreaseConversationCount();

            incrementSocketUpdateCount();
          }
        } else if (data.type === 'add') {
          // Scenario - Tag Addition
          const addedTag = data.new_tag[0];
          const oldTagIds = data.contact.tag_ids.filter(
            (item) => item !== addedTag._id
          );

          const updateType = addedTagCheck(addedTag, oldTagIds);

          console.log('tag addition -> ', updateType);

          if (updateType === 'insert') {
            //TODO: Add the conversation to the top and increment the count
            // addConversationToTop(data.contact);
            // increaseConversationCount();

            incrementSocketUpdateCount();
          }
        }
      }
    }
    console.log('-----');
  };

  // Function to handle contact-status-update events
  const handleStatusUpdate = (data) => {
    console.log('-----');
    console.log('contact-status-update', data.contact._id, data.contact.status);
    if (data.origin_client_id !== clientId) {
      if (contactId === data.contact._id) {
        console.log('current ontact status change, so updating status');
        // if current contact is the socket contact, then update the status
        setContactStatus(data.contact.status);
      }

      if (
        selectedConversationStatus.value !== 'all' &&
        selectedConversationStatus.value !== data.contact.status
      ) {
        console.log('contact status not same as selected status');
        // TODO: Scenario - conversationStatus === open & currentContactStatus === close or vice-versa -> if user present in users list, remove the user
        // removeConversation(data.contact._id);
        // decreaseConversationCount();

        incrementSocketUpdateCount();
      } else if (selectedConversationStatus.value === data.contact.status) {
        console.log('contact status same as selectedStatus');
        //TODO: Scenario - conversationStatus === open & currentContactStatus === open or close-close -> push the user to the top of the list and increase the conversation count
        // addConversationToTop(data.contact);
        // increaseConversationCount();

        incrementSocketUpdateCount();
      }
    }
    console.log('-----');
  };

  // Function to handle reactions events
  const handleReaction = (data) => {
    // if current contact is the socket contact, then update the reaction of the messageId
    if (contactId === data.contact_id) {
      console.log('Message Reaction Socket', data.message_id, data.emoji);
      setMessageReaction(data.message_id, data.emoji);
    }
  };

  // Function to handle read-receipts events
  const handleReadReceipts = (data) => {
    // if current contact is the socket contact, then update the is_seen of the messageId
    if (contactId === data.contact_id) {
      console.log('Message Read Receipt Socket', data.message_id);
      setMessageReadReceipt(data.message_id);
    }
  };

  const { channel } = useChannel(channelName, ({ name, data }) => {
    if (name === 'new-message') {
      // socket event for new-messages
      handleNewMessage(data);
    } else if (name === 'reactions') {
      // socket event for reactions for messages
      handleReaction(data);
    } else if (name === 'read-receipts') {
      // socket event for is_seen for messages
      handleReadReceipts(data);
    } else if (name === 'agent-update') {
      // socket event for owner changes to contacts
      handleAgentUpdate(data);
    } else if (name === 'tag-update') {
      // socket event for tag removal or tag addition to contacts
      handleTagUpdate(data);
    } else if (name === 'contact-status-update') {
      // socket event for status changes to contacts
      handleStatusUpdate(data);
    }
  });

  return null;
};
