import Mixpanel from './mixpanel';

/* 

**General Events**

Login
	DONE: Login Successful - login_suceeded
	DONE: Login Failure - login_failed

Register
	Register Successful - register_suceeded
	Register Failure - register_failed


**User specific Events**

Global Default Properties
	customer_id
	user_id

Other Events
	User Dropdown Button - user_dropdown_clicked
	User Profile Button - user_profile_clicked
	Theme Button - theme_button_clicked	

Conversation List Events

	DONE: Tag Filter Button - tag_filter_button_clicked
		usage_type (mouse/keyboard)
	DONE: Owner Filter Button - owner_filter_button_clicked
		usage_type (mouse/keyboard)
	DONE: Search Input - search_input_clicked
		usage_type (mouse/keyboard)
	DONE: Search Text Clear Button - search_text_clear_button_clicked
		usage_type (mouse/keyboard)
	DONE: Sort Filter Button - sort_filter_button_clicked
		usage_type (mouse/keyboard)
	(Pending for mouse) Partial: Status Filter Button - status_filter_button_clicked
		usage_type - (mouse/keyboard)
	DONE: Refresh Conversation Button - refresh_conversation_button_clicked
	DONE: Conversation Filter - conversation_filter
		filteroptions(each option is unique property)
			(search_text
			filter_status
			filter_sort
			filter_tags
			filter_owners)
	DONE: Reset Button - filter_reset_button_clicked
		usage_type (mouse/keyboard)

	Reference below..
	(...Search Text
		search_text
	Status Filter
		filter_status
	Sort Filter
		filter_sort
	Tag Filter
		filter_tags
	Owner Filter
		filter_owners...)

Contact View Events

Specific Default Properties
contact_id
contact_name	

	DONE: Profile Button - profile_button_clicked
		type (open/close)
		usage_type (mouse/keyboard)
	DONE: Cross button in user profile details - profile_close_button_clicked
	DONE: Status Change Button - status_button_clicked
		usage_type (mouse/keyboard)
	DONE: Tag Button - tag_button_clicked
		usage_type (mouse/keyboard)
	DONE:Contact Status Change - status_changed
		status - (open/archive)
	DONE: Add Tag - tag_added
		tag_id
		tag_name
	DONE: Remove Tag - tag_removed
		tag_id
		tag_name
	(Pending for mouse) Partial: Owner Button - owner_button_clicked
		usage_type (mouse/keyboard)
	DONE: Owner Change - owner_changed
		previous_owner_id
		new_owner_id
	DONE: Note Focus - note_clicked
		usage_type (mouse/keyboard)
	DONE: Notes - noted_updated
		length
	DONE: IG Link Click - ig_link_clicked
  DONE: Message Input Click - message_input_clicked
    usage_type (mouse/keyboard)
  DONE: Ctrl+Enter Functionality Used - quick_close_contact
	DONE: Send Message - send_message
		length
		type (text)
	DONE: Contact Messages Focus - contact_messages_focus 
	TODO: Contact Scroll (upward scroll for fetching older messages of a contact) - contact_messages_fetch_request
		page_number
	DONE: Contact FB Fetch - Fetch from IG Button click - contact_messages_fb_fetch_request
	TODO: Conversation Scroll (downward scroll for fetching next page) - conversation_list_fetch_request
	filteroptions (each option is individual property)
			(search_text
			filter_status
			filter_sort
			filter_tags
			filter_owners)
		page_number
	DONE: Contact/Conversation Item click - contact_clicked
		usage_type (mouse/keyboard)


Ably Connection Events
	DONE: Connection Status Change - ably_connection_status_changed
		status - (connected/disconnected/....)

		TODO:
  Partial: Event to track DropDown for Status Filter via mouse
  Partial: Event to track SelectTrigger for Contact Owner Change via mouse
  Event to track PageView which is custom implemented (ref: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-page-views)
  Event to track Conversation Scroll to next pages
  Event to track Contact Messages Scroll to next pages

*/

export const trackThemeChange = (theme) => {
  Mixpanel.track('theme_button_clicked', { theme });
};

export const trackUserProfileClicked = () => {
  Mixpanel.track('user_profile_clicked');
};

export const trackLogOutAndMixpanelReset = () => {
  Mixpanel.track('logout_succeeded');
  Mixpanel.reset();
};

const BuildMixpanelIdentity = (user) => {
  Mixpanel.identify(user._id);
  Mixpanel.people.set({
    $name: `${user.first_name} ${user.last_name}`,
    $email: user.email,
    relogin_date: new Date().toString(),
  });
  Mixpanel.register({
    email: user.email,
    role: user.role,
    customer_id: user.customer_id,
  });
};

export const trackPageView = (data) => {
  Mixpanel.track_custom_pageview(data);
};

export const trackLogin = (user) => {
  BuildMixpanelIdentity(user);
  Mixpanel.track('login_succeeded');
};

export const trackLoginFailed = (error) => {
  Mixpanel.track('login_failed', { error });
};

export const trackUserAuthenticationSuccess = (user) => {
  BuildMixpanelIdentity(user);
  Mixpanel.track('authenticating_user_succeeded');
};

export const trackUserAuthenticationFailed = (error) => {
  Mixpanel.track('authenticating_user_failed', { error });
};

export const trackTagFilterButtonClicked = (mode) => {
  Mixpanel.track('tag_filter_button_clicked', {
    usage_type: mode,
  });
};

export const trackOwnerFilterButtonClicked = (mode) => {
  Mixpanel.track('owner_filter_button_clicked', {
    usage_type: mode,
  });
};

export const trackChannelFilterButtonClicked = (mode) => {
  Mixpanel.track('channel_filter_button_clicked', {
    usage_type: mode,
  });
};

export const trackSortFilterButtonClicked = (mode) => {
  Mixpanel.track('sort_filter_button_clicked', {
    usage_type: mode,
  });
};

export const trackSearchTextClearButtonClicked = (mode) => {
  Mixpanel.track('search_text_clear_button_clicked', {
    usage_type: mode,
  });
};

export const trackSearchTextInputClicked = (mode) => {
  Mixpanel.track('search_input_clicked', {
    usage_type: mode,
  });
};

export const trackResetFilterButtonClicked = (mode) => {
  Mixpanel.track('reset_filter_button_clicked', {
    usage_type: mode,
  });
};

export const trackStatusFilterButtonClicked = (mode) => {
  Mixpanel.track('status_filter_button_clicked', {
    usage_type: mode,
  });
};

export const trackRefreshConversationButtonClicked = () => {
  Mixpanel.track('refresh_conversation_button_clicked');
};

export const trackConversationFetch = (
  searchText,
  filterStatus,
  filterSort,
  filterTags,
  filterOwners
) => {
  Mixpanel.track('conversation_filter', {
    search_text: searchText,
    filter_status: filterStatus,
    filter_sort: filterSort,
    filter_tag: filterTags,
    filter_owner: filterOwners,
  });
};

export const trackIGLinkClicked = () => {
  Mixpanel.track('ig_link_clicked', {
    ...Mixpanel.get_current_contact(),
  });
};

export const trackProfileCloseButtonClicked = () => {
  Mixpanel.track('profile_close_button_clicked', {
    ...Mixpanel.get_current_contact(),
  });
};

export const trackUserProfileButtonClicked = (type, mode) => {
  Mixpanel.track('profile_button_clicked', {
    ...Mixpanel.get_current_contact(),
    type: type === true ? 'close' : 'open',
    usage_type: mode,
  });
};

export const trackContactStatusButtonClicked = (mode) => {
  Mixpanel.track('status_button_clicked', {
    ...Mixpanel.get_current_contact(),
    usage_type: mode,
  });
};

export const trackContactStatusChange = (status) => {
  Mixpanel.track('status_changed', {
    ...Mixpanel.get_current_contact(),
    status: status,
    status_change_type: 'user',
  });
};

export const trackContactTagButtonClicked = (mode) => {
  Mixpanel.track('tag_button_clicked', {
    ...Mixpanel.get_current_contact(),
    usage_type: mode,
  });
};

export const trackContactOwnerButtonClicked = (mode) => {
  Mixpanel.track('owner_button_clicked', {
    ...Mixpanel.get_current_contact(),
    usage_type: mode,
  });
};

export const trackContactNoteClicked = (mode) => {
  Mixpanel.track('note_clicked', {
    ...Mixpanel.get_current_contact(),
    usage_type: mode,
  });
};

export const trackContactNoteUpdated = (noteLength) => {
  Mixpanel.track('noted_updated', {
    ...Mixpanel.get_current_contact(),
    note_length: noteLength,
  });
};

export const trackTagAdded = (tagId, tagName) => {
  Mixpanel.track('tag_added', {
    ...Mixpanel.get_current_contact(),
    tag_id: tagId,
    tag_name: tagName,
  });
};

export const trackTagRemoved = (tagId, tagName) => {
  Mixpanel.track('tag_removed', {
    ...Mixpanel.get_current_contact(),
    tag_id: tagId,
    tag_name: tagName,
  });
};

export const trackOwnerChanged = (previousOwnerId, newOwnerId) => {
  Mixpanel.track('owner_changed', {
    ...Mixpanel.get_current_contact(),
    previous_owner_id: previousOwnerId,
    new_owner_id: newOwnerId,
  });
};

export const trackSendMessage = (messageLength, messageType) => {
  Mixpanel.track('send_message', {
    ...Mixpanel.get_current_contact(),
    message_length: messageLength,
    message_type: messageType,
  });
};

export const trackContactMessagesFocus = (mode) => {
  Mixpanel.track('contact_messages_focus', {
    usage_method: mode,
  });
};

export const trackContactFocus = (mode) => {
  Mixpanel.track('contact_clicked', {
    usage_method: mode,
  });
};

export const trackContatFBFetch = () => {
  Mixpanel.track('contact_messages_fb_fetch_request', {
    ...Mixpanel.get_current_contact(),
  });
};

export const trackMessageInputClicked = (mode) => {
  Mixpanel.track('message_input_clicked', {
    ...Mixpanel.get_current_contact(),
    usage_type: mode,
  });
};

export const trackQuickCloseContactFeature = () => {
  Mixpanel.track('quick_close_contact', {
    ...Mixpanel.get_current_contact(),
  });
};

export const trackAblyConnectionStatusChange = (state) => {
  Mixpanel.track('ably_connection_status_changed', {
    state: state,
  });
};
