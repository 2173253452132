import logo from '@/assets/images/logo.png';
import { Link } from 'react-router-dom';

import { UserLoginForm } from '@/components/login/UserLoginForm';

const Login = () => {
  return (
    <>
      <div className='container relative flex h-screen items-center'>
        <Link
          to='/register'
          className='absolute right-4 top-4 md:right-8 md:top-8'
        >
          Register
        </Link>
        <div className='mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]'>
          <div className='flex h-16 shrink-0 items-center justify-center'>
            <img className='h-20 w-auto' src={logo} alt='Your Company' />
          </div>
          <div className='flex flex-col space-y-2 text-center'>
            <h1 className='text-2xl font-semibold tracking-tight'>Login</h1>
          </div>
          <UserLoginForm />
          <p className='px-8 text-center text-sm text-muted-foreground'>
            By clicking continue, you agree to our{' '}
            <Link
              href='#'
              className='underline underline-offset-4 hover:text-primary'
            >
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link
              href='#'
              className='underline underline-offset-4 hover:text-primary'
            >
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
