import avatarFB from '@/assets/images/avatar-fb.png';
import avatarIG from '@/assets/images/avatar-ig.png';
import useContactStore from '@/stores/ContactStore';
import { FacebookIcon, InstagramIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { trackIGLinkClicked } from '@/lib/analytics-event';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';

import ContactOwner from './ContactOwner';
import ContactTags from './ContactTag';
import TipTapEditor from './Notes';

const ContactDetails = () => {
  const { fullName, profilePictureUrl, igUsername, plan, contactId, channel } =
    useContactStore(
      useShallow((state) => ({
        fullName: state.fullname,
        profilePictureUrl: state.profile_picture_url,
        igUsername: state.ig_username,
        plan: state.plan,
        contactId: state._id,
        channel: state.channel,
      }))
    );

  if (!contactId) return null;

  return (
    <div className='flex w-full flex-col space-y-4 overflow-hidden '>
      <div className='flex flex-col space-y-2 border-b border-borderColor py-1 pt-5'>
        <Avatar className='h-20 w-20 self-center'>
          <AvatarImage
            src={
              profilePictureUrl
                ? profilePictureUrl
                : 'https://files.sbccrm.com/sbccrm/sbccrm/contacts/pics/no-pic.jpg'
            }
          />
          <AvatarFallback>{fullName.charAt(0)}</AvatarFallback>
        </Avatar>

        <div className='flex flex-col items-center py-2'>
          <h5 className='px-3 text-center text-base font-medium'>{fullName}</h5>
          {channel === 'facebook' ? (
            <Button variant='link' size='sm' className='cursor-default'>
              <img src={avatarFB} className='mr-2 aspect-square h-4 w-4' />
              {/* <FacebookIcon className='mr-2 h-4 w-4 text-foreground' /> */}
            </Button>
          ) : channel === 'instagram' ? (
            <Link
              to={`https://www.instagram.com/${igUsername}`}
              target='_blank'
              className='-mt-1.5'
              onClick={() => trackIGLinkClicked()}
            >
              <Button variant='link' size='sm'>
                <img src={avatarIG} className='mr-2 aspect-square h-4 w-4' />
                {/* <InstagramIcon className='mr-2 h-4 w-4 text-foreground' /> */}
                {igUsername}
              </Button>
            </Link>
          ) : null}
          {plan ? (
            <div>
              <Button>
                {plan?.name} - ${plan?.amount}
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      <ContactTags />

      <ContactOwner />

      <div className='space-y-2 border-b border-borderColor px-4'>
        <div className='text-sm font-medium'>Notes</div>
        <TipTapEditor />
      </div>
    </div>
  );
};

export default ContactDetails;
